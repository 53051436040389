import {PerpPositionStatus} from "@/components/Perps/types";
import {ErrorPanel} from "@/components/ErrorPanel";
import {EthValue} from "@/components/Perps/EthValue";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {PnLTooltip} from "@/components/Perps/positions/PnLTooltip";
import React from "react";
import {twMerge} from "tailwind-merge";

export const PnLDisplay = ({positionStatus, className}: { positionStatus: PerpPositionStatus, className?: string | undefined }) => {
  return (
    <div>
      {
        positionStatus.hasError ?
          <ErrorPanel id={`pnl_${positionStatus.position.id}`} message="Error" /> :
          <EthValue
            showPlus={true}
            className={twMerge("cursor-pointer", className || '')}
            valueClassName="underline decoration-dotted underline-offset-4"
            id={`pnl_${positionStatus.position.id}`}
            value={BigInt(positionStatus.netValue) + BigInt(positionStatus.fee) - BigInt(positionStatus.position.downPaymentRaw)}
            valueForPercent={BigInt(positionStatus.netValue) + BigInt(positionStatus.fee)}
            original={BigInt(positionStatus.position.downPaymentRaw)} />
      }
      <ReactTooltip
        anchorSelect={`#pnl_${positionStatus.position.id}`}
        id={`tooltip_pnl_${positionStatus.position.id}`}
        place="top"
        className="z-50"
        style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
      >
        {positionStatus.hasError ? "Something went wrong. Please contact the team from Discord" : <PnLTooltip positionStatus={positionStatus}/>}
      </ReactTooltip>
    </div>
  )
}
