import {PerpPositionStatus, Position} from "@/components/Perps/types";

export const getLeverage = (p: Position) => {
  const downPayment = BigInt(p.downPaymentRaw);
  if (p.side === "LONG") {
    const leverage = (BigInt(p.principalRaw) + downPayment) * 10n / downPayment;
    return parseFloat((Number(leverage) / 10).toFixed(1));
  }
  const leverage = (BigInt(p.collateralAmountRaw) - downPayment) * 10n / downPayment;
  return parseFloat((Number(leverage) / 10).toFixed(1));
}

export const getPnlPercent = (p: PerpPositionStatus, denom = 1000): number => {
  return (Number(BigInt(p.netValue) * BigInt(denom) / BigInt(p.position.downPaymentRaw)) / denom - 1) * 100;
}