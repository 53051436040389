import {getLeverage} from "@/components/Perps/util";
import {PerpToken, Position} from "@/components/Perps/types";
import classNames from "classnames";
import {BLAST_USDC} from "@/util/constants";
import {twMerge} from "tailwind-merge";

interface Props {
  position: Position;
  token: PerpToken;
  onTokenClicked: (p: PerpToken) => any;
  imageSize?: number;
  className?: string;
}

export const PositionHeader = ({position, token, onTokenClicked, imageSize, className}: Props) => {
  let side = position.side;
  let symbol = token.symbol;
  let base = "ETH";
  const isUSDC = token.address === BLAST_USDC;
  if (isUSDC) {
    side = side === "LONG" ? "SHORT" : "LONG";
    symbol = "ETH";
    base = "USDB";
  }
  return (
    <div className={twMerge("flex flex-row items-center gap-2 hover:cursor-pointer hover:underline hover:underline-offset-2 text-sm", className || '')}
         onClick={() => onTokenClicked(token)}>
      <img src={token.imageUrl} alt={symbol} className={`w-${imageSize || 8} h-${imageSize || 8} rounded-full`}/>
      <div>
        <div className={classNames({
          "text-call": side === "LONG",
          "text-put": side === "SHORT",
        })}>
          {getLeverage(position)}x {side}
        </div>
        <span>{symbol}<span className="text-neutral-content">/{base}</span></span>
      </div>
    </div>
  )
}