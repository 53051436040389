import {PerpPositionStatus} from "@/components/Perps/types";
import {EthValue} from "@/components/Perps/EthValue";
import {multiplyByPrice} from "@/util/converters";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {SizeTooltip} from "@/components/Perps/positions/SizeTooltip";
import React from "react";

export interface Props {
  positionStatus: PerpPositionStatus
}

export const SizeView = ({positionStatus}: Props) => {
  const position = positionStatus.position;

  const getSize = () => {
    if (position.side === "SHORT") {
      return BigInt(position.collateralAmountRaw);
    } else {
      return BigInt(position.principalRaw) + BigInt(position.downPaymentRaw);
    }
  }

  return (
    <>
      <EthValue
        className="cursor-pointer"
        id={`size_${position.id}`}
        value={getSize()}
      />
      <ReactTooltip
        anchorSelect={`#size_${position.id}`}
        id={`tooltip_size_${position.id}`}
        place="top"
        className="z-50"
        style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
      >
        <SizeTooltip positionStatus={positionStatus}/>
      </ReactTooltip>
    </>
  )
}