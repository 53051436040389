import {PerpPositionStatus} from "@/components/Perps/types";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import React from "react";
import {formatValue, multiplyByPrice} from "@/util/converters";
import {useEthPrice} from "@/contexts/EthPriceContext";

export const SizeTooltip = ({positionStatus}: { positionStatus: PerpPositionStatus }) => {
    const {ethPrice} = useEthPrice();

    const isLong = positionStatus.position.side === "LONG";
    const size = isLong
        ? positionStatus.position.principal + positionStatus.position.downPayment
        : positionStatus.position.collateralAmount;

    return (
        <div className="standard-stack p-2">
            <SplitLabelValue label="Collateral" className="text-white">
                <div className="flex flex-row gap-2">
                    <span>{positionStatus.position.collateralAmount.toLocaleString([], {maximumFractionDigits: 4})}</span>
                    <span>{isLong ? positionStatus.token.symbol : "ETH"}</span>
                </div>
            </SplitLabelValue>
            <SplitLabelValue label="Principal" className="text-white">
                <div className="flex flex-row gap-2">
                    <span>{positionStatus.position.principal.toLocaleString([], {maximumFractionDigits: 4})}</span>
                    <span>{isLong ? "ETH" : positionStatus.token.symbol}</span>
                </div>
            </SplitLabelValue>
          {
            ethPrice &&
            <SplitLabelValue label="USD Value" className="text-white">
              <span>${(size * ethPrice).toLocaleString([], {maximumFractionDigits: 2})}</span>
            </SplitLabelValue>
          }
        </div>
    )
}
