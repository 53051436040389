import { BiLinkExternal } from "react-icons/bi";
import { ethers } from "ethers";
import Link from "next/link";
import {twMerge} from "tailwind-merge";
import {getBlockExplorer} from "@/util/chainConstants";

const getEtherscanUrl = (address?: string) => {
  return `${getBlockExplorer()}/tx/${address}`;
}

export const EtherscanTransactionDisplay = ({
  hash,
  label,
  short = false,
  className = ''
}: {
  hash: string;
  label?: any;
  short?: boolean;
  className?: string;
}) => {
  let addressToDisplay = hash;
  if (short) {
    addressToDisplay = `${hash.slice(0, 4)}...${hash.slice(
      hash.length - 4,
      hash.length
    )}`;
  }

  if (hash === ethers.constants.AddressZero) {
    return <div className="font-flight">{addressToDisplay}</div>;
  }
  return (
    <Link href={getEtherscanUrl(hash)}
          target="_blank"
          className={twMerge("hover:text-white", className || '')}
          onClick={e => e.stopPropagation()}>
      <div className="flex flex-row items-center justify-start gap-1 font-light">
        {label ? label : addressToDisplay}
      </div>
    </Link>
  );
};
