import {PerpPositionStatus} from "@/components/Perps/types";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import {EthValue} from "@/components/Perps/EthValue";
import React from "react";
import {formatUnits} from "viem";
import {formatValue} from "@/util/converters";

export const PnLTooltip = ({positionStatus}: { positionStatus: PerpPositionStatus }) => {
  const position = positionStatus.position;
  const downPayment = BigInt(position.downPaymentRaw);
  const netValue = BigInt(positionStatus.netValue);
  const fee = BigInt(positionStatus.fee);

  const ethInterest =
    position.side === "LONG"
      ? BigInt(positionStatus.interestPaid)
      : BigInt(positionStatus.interestPaid) * BigInt(Math.round(positionStatus.markPrice * Math.pow(10, 10))) / BigInt(Math.pow(10, 10));
  const hourlyBorrowRate = positionStatus.apr / (365 * 24);
  const hourlyInterestAmountFormatted = position.principal * hourlyBorrowRate;

  return (
    <div className="standard-stack p-2">
      <SplitLabelValue label="Down Payment" className="text-white">
        <EthValue value={downPayment} />
      </SplitLabelValue>
      <hr className="border-neutral-content/30"/>
      {
        position.side === "SHORT" &&
        <SplitLabelValue label="Interest Paid" className="text-white">
          <span>{formatValue(positionStatus.interestPaid, 6, positionStatus.token.decimals)} {positionStatus.token.symbol}</span>
        </SplitLabelValue>
      }
      <SplitLabelValue label={position.side === "SHORT" ? "Interest Paid (ETH Value)" : "Interest Paid"} className="text-white">
        <EthValue value={positionStatus.interestPaidEthValue} />
      </SplitLabelValue>
      <SplitLabelValue label="Borrow Rate" className="text-white">
        <div className="standard-stack items-end">
          <div>{(hourlyBorrowRate * 100).toFixed(4)}% / 1h</div>
        </div>
      </SplitLabelValue>
      <SplitLabelValue label="Borrow Amount" className="text-white">
        <div className="standard-stack items-end">
          <div>{hourlyInterestAmountFormatted.toLocaleString([], {maximumFractionDigits: 10})} {positionStatus.position.side === 'LONG' ? "ETH" : positionStatus.token.symbol} / 1h</div>
        </div>
      </SplitLabelValue>
      <hr className="border-neutral-content/30"/>
      <SplitLabelValue label="PnL" className="text-white">
        <EthValue className="flex-col items-end"
                  value={netValue + fee - downPayment}
                  valueForPercent={netValue + fee}
                  original={downPayment}/>
        </SplitLabelValue>
      <hr className="border-neutral-content/30"/>
      <SplitLabelValue label="Close Fees" className="text-white">
        <EthValue value={fee} />
      </SplitLabelValue>
      <SplitLabelValue label="You Receive" className="text-white">
        <EthValue value={netValue} fractions={4} />
      </SplitLabelValue>
    </div>
  )
}
